import { Link } from "gatsby";
import React, { useState, useEffect } from "react";
import http from '../_api/http';
const Blog = () => {
  const [loading, setLoading] = useState(true);
  const [blogs, setBlog] = useState([]);
  const PUBLIC_URL = process.env.REACT_APP_APIURL;
  useEffect(() => {
    // alert(process.env.REACT_APP_APIURL);
    http.get('api/blog/ApiCallBlogAllList').then((res) => {
        setBlog(res.data);
        setLoading(false);
    });
  }, []);
  return (
    <section className="blog-one  blog-one__home-two">
      <div className="container">
        <div className="row">
          {loading
            ? <h4>View Blogs Loading...</h4>
            :
            <>
              {blogs.map((item, i) =>
                <div className="col-lg-3" key={i}>
                  <div className="blog-one__single">
                    <div className="blog-one__image">
                      <img src={PUBLIC_URL + "BlogImage/" + item.image} alt="" />
                      <a className="blog-one__plus" href="news-details.html">
                        <i className="kipso-icon-plus-symbol"></i>
                      </a>
                    </div>
                    <div className="blog-one__content text-center">
                      <h2 className="blog-one__title">
                        <a href="#">
                          {item.blog_title}
                        </a>
                      </h2>
                      <p dangerouslySetInnerHTML={{ __html: item.blog_description }} className="blog-one__text">
                        {/* {item.blog_description} */}
                      </p>
                      {/* <Link to={`/blog?id=${item.id}`} state={{ id: item.id }} className="thm-btn">
                        Read More
                      </Link> */}
                    </div>
                  </div>
                </div>
              )}
            </>
          }
        </div>
      </div>
    </section>
  );
};

export default Blog;

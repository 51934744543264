import React from 'react';
import Layout from "../components/Layout";
import NavOne from "../components/NavOne";
import PageHeaderAbout from "../components/PageHeaderAbout";
import Footer from "../components/Footer";
import Blog from "../components/Blog";
import Topbar from "../components/Topbar";

const AllBlogPage = () => {
    return (
        <Layout pageTitle="Shanti Asiatic School | Blog">
            <Topbar />
            <NavOne />
            <PageHeaderAbout title="Blog" />
            <Blog />
            <Footer />
        </Layout>
    );
};

export default AllBlogPage;
